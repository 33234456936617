.gallery-container{
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-auto-rows: 240px;
    padding: 0 1rem;
    margin: 0;
}
@media screen and (min-width: 600px){
    .featured{
        grid-column: span 2 / auto;
        grid-row: span 2 / auto;
    }
    .tall{
        grid-row: span 2 / auto;
    }
    .wide{
        grid-column: span 2 / auto;
    }
}